import styled from 'styled-components';
import { PageContainer } from '../../components/styles';

export const Root = styled(PageContainer)`
  padding: 20px 40px;

  hr {
    margin-bottom: 45px;
  }

  @media (max-width: 991px) {
    padding: 0 0 40px;

    hr {
      margin-bottom: 25px;
      margin-top: 50px;
    }
  }
`;

export const AppointmentS = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 0 10px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    padding: 0 0 10px;
  }
`;

export const AppointmentTitle = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;

  @media (max-width: 991px) {
    font-size: 1.2em;
  }
`;

export const AppointmentContent = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;

  @media (max-width: 991px) {
    font-size: 1.2em;
  }
`;

// export const LinkS = styled(Link)`
//   font-family: Gotham-Book;
//   font-size: 1.2em;

//   &:hover {
//     text-decoration: none;
//     opacity: .6;
//   }

//   @media (max-width: 991px) {
//     font-size: 1.1em;
//   }
// `;

// export const ChangeBlock = styled.div`
//   text-align: right;
//   a {
//     color: ${props => props.colorsucses || 'rgb(31, 130, 224)'};
//   }
// `;

export const FormElemGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 10px;

  @media (max-width: 991px) {
    display: block;
  }

  textarea {
    height: 100px;
    resize: none;
  }

  select {
    font-size: 1em;
  }
`;

export const ButtonDescription = styled.p`
  font-family: Gotham-Medium;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
