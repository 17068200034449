import camelcaseKeys from 'camelcase-keys-deep';

import {
  CHANGE_AVATAR_USER_REQUEST,
  CHANGE_AVATAR_USER_FULFILLED,
  CHANGE_AVATAR_USER_REJECTED,
  EDIT_INFO_USER_REQUEST,
  EDIT_INFO_USER_FULFILLED,
  EDIT_INFO_USER_REJECTED,
  ADD_USER_FAMILY_MEMBER_REQUEST,
  ADD_USER_FAMILY_MEMBER_FULFILLED,
  ADD_USER_FAMILY_MEMBER_REJECTED,
  EDIT_USER_FAMILY_MEMBER_REQUEST,
  EDIT_USER_FAMILY_MEMBER_FULFILLED,
  EDIT_USER_FAMILY_MEMBER_REJECTED,
  DELETE_USER_FAMILY_MEMBER_REQUEST,
  DELETE_USER_FAMILY_MEMBER_FULFILLED,
  DELETE_USER_FAMILY_MEMBER_REJECTED,
  FETCH_USER_FAMILY_MEMBER_REQUEST,
  FETCH_USER_FAMILY_MEMBER_FULFILLED,
  FETCH_USER_FAMILY_MEMBER_REJECTED,
  FETCH_USER_ONCOMING_APPS_REQUEST,
  FETCH_USER_ONCOMING_APPS_FULFILLED,
  FETCH_USER_ONCOMING_APPS_REJECTED,
  AUTHORIZE_USER,
  FETCH_PRACTICES_FULFILLED,
  FETCH_PRACTICES_REJECTED,
  SET_PATIENT_REQUEST,
} from '../constants';
import { hostProtocol, hostIp, hostPort } from '../store/api';
import {
  jwtHeaders, uri, uriWithParams, mapPractices, getLocationOptions,
  getFamilyMembersOptions, handleErrors,
} from '../utils/apiUtils';
import { logout } from './authActions';

export function changeAvatarUser(photo, token, userId) {
  return (dispatch) => {
    dispatch({ type: CHANGE_AVATAR_USER_REQUEST });
    fetch(`${hostProtocol}://${hostIp}:${hostPort}/api/photo/update/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        bearer: token,
      },
      body: `&photo=${encodeURIComponent(photo)}`,
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: CHANGE_AVATAR_USER_FULFILLED,
          payload: camelcaseKeys(json, { deep: true }),
        });
        const user = JSON.parse(localStorage.getItem('user'));
        user.photo = camelcaseKeys(json, { deep: true }).data.item.photoUrl;
        localStorage.setItem('user', JSON.stringify(user));
      })
      .catch((error) => {
        dispatch({ type: CHANGE_AVATAR_USER_REJECTED, payload: error });
      });
  };
}

export function editInfoUser(request, token) {
  const body = JSON.stringify({
    Account: {
      first_name: request.firstName,
      last_name: request.lastName,
      birthdate: request.birthday,
      phone: request.phone,
      email: request.email,
      address: request.address,
      address2: '',
      city: request.city,
      state: request.state,
      zipcode: request.zipcode,
    },
  });
  return (dispatch) => {
    dispatch({ type: EDIT_INFO_USER_REQUEST });
    fetch(
      uri('account'),
      {
        method: 'PUT',
        headers: jwtHeaders(token),
        body,
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then(() => {
        dispatch({ type: EDIT_INFO_USER_FULFILLED, payload: request });
        dispatch(getUserFamilyMembers(token));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: EDIT_INFO_USER_REJECTED, payload: error });
        }
      });
  };
}

export function getUserFamilyMembers(token) {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_FAMILY_MEMBER_REQUEST });
    fetch(
      uri('profile'),
      {
        method: 'GET',
        headers: jwtHeaders(token),
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const familyMembers = camelcaseKeys(json.data.items, { deep: true });
        const familyMembersOptions = getFamilyMembersOptions(familyMembers);
        dispatch(getMainPatientId(familyMembers));
        dispatch({
          type: FETCH_USER_FAMILY_MEMBER_FULFILLED,
          payload: {
            familyMembers,
            familyMembersOptions,
          },
        });
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: FETCH_USER_FAMILY_MEMBER_REJECTED, payload: error });
        }
      });
  };
}

export function getPracticesList(token) {
  return (dispatch) => {
    fetch(
      uri('practices'),
      {
        method: 'GET',
        headers: jwtHeaders(token),
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const items = json.data.items || []; // Treat null as an empty array
        return mapPractices(items);
      })
      .then((practices) => {
        const payloadData = {
          practicesList: camelcaseKeys(practices, { deep: true }),
          practicesOptions: getLocationOptions(practices),
        };
        dispatch({
          type: FETCH_PRACTICES_FULFILLED,
          payload: payloadData,
        });
      })
      .catch((error) => {
        if (error.data.errors?.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: FETCH_PRACTICES_REJECTED, payload: error });
        }
      });
  };
}

export function addUserFamilyMember(request, token) {
  const body = JSON.stringify({
    FamilyMember: {
      first_name: request.firstName,
      last_name: request.lastName,
      birthdate: request.birthday,
      phone: request.phone,
      email: request.email,
      is_under_18: request.isUnder18,
    },
  });
  return (dispatch) => {
    dispatch({ type: ADD_USER_FAMILY_MEMBER_REQUEST });
    fetch(
      uri('profile'),
      {
        method: 'POST',
        headers: jwtHeaders(token),
        body,
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then(() => {
        dispatch({ type: ADD_USER_FAMILY_MEMBER_FULFILLED });
        dispatch(getUserFamilyMembers(token));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: ADD_USER_FAMILY_MEMBER_REJECTED, payload: error });
        }
      });
  };
}

export function editUserFamilyMember(request, token, id, mainUserID) {
  const body = JSON.stringify({
    FamilyMember: {
      first_name: request.firstName,
      last_name: request.lastName,
      birthdate: request.birthday,
      phone: request.phone,
      email: request.email,
      is_under_18: request.isUnder18,
    },
  });
  return (dispatch) => {
    dispatch({ type: EDIT_USER_FAMILY_MEMBER_REQUEST });
    fetch(
      uriWithParams('profile-option', id),
      {
        method: 'PATCH',
        headers: jwtHeaders(token),
        body,
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then(() => {
        dispatch({ type: EDIT_USER_FAMILY_MEMBER_FULFILLED });
        dispatch(setPatientRequest(mainUserID));
        dispatch(getUserFamilyMembers(token));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: EDIT_USER_FAMILY_MEMBER_REJECTED, payload: error });
        }
      });
  };
}

export function deleteUserFamilyMember(userId, token) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_FAMILY_MEMBER_REQUEST });
    fetch(
      uriWithParams('profile-option', userId),
      {
        method: 'DELETE',
        headers: jwtHeaders(token),
      },
    )
      .then((response) => handleErrors(response, dispatch))
      .then((response) => response.json())
      .then(() => {
        dispatch({ type: DELETE_USER_FAMILY_MEMBER_FULFILLED });
        dispatch(getUserFamilyMembers(token));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: DELETE_USER_FAMILY_MEMBER_REJECTED, payload: error });
        }
      });
  };
}

export function getUpcomingApps(token) {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_ONCOMING_APPS_REQUEST });
    fetch(
      uri('upcoming-apps'),
      {
        method: 'GET',
        headers: jwtHeaders(token),
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const jsonCamel = camelcaseKeys(json.data.item, { deep: true });
        dispatch({ type: FETCH_USER_ONCOMING_APPS_FULFILLED, payload: jsonCamel });
        sessionStorage.setItem('upcomingApps', JSON.stringify(jsonCamel));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: FETCH_USER_ONCOMING_APPS_REJECTED, payload: error });
        }
      });
  };
}

export function authorizeUser() {
  return (dispatch) => {
    dispatch({ type: AUTHORIZE_USER, payload: true });
  };
}

export function setPatientRequest(patient) {
  return (dispatch) => {
    dispatch({ type: SET_PATIENT_REQUEST, payload: patient });
  };
}

export const getMainPatientId = (familyMembers) => {
  const patient = familyMembers.find((x) => x.patient.isMaster === true);
  return (dispatch) => {
    dispatch({ type: SET_PATIENT_REQUEST, payload: patient.patient.id });
  };
};
