import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalHeader, Row, Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ModalS = styled(Modal)`
  text-align: center;
`;

// const Title = styled.span`
//  font-size: 1.5em;
//  font-family: Gotham-Book;
//  line-height: 1.2;
//  color: ${props => props.fontcolor || 'rgb(0, 0, 0)'};
//  opacity: .8;
// `;

const MessageBody = styled.div`
  font-family: Gotham-Book;
  padding: 10px 10px 30px;
  position: relative;
  top: 10px;
  text-align: justify;
  > div {
    display: block;
    padding: 20px;
    background: -moz-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000', endColorstr='#1e5799',GradientType=1
    ); /* IE6-9 fallback on horizontal gradient */
  }
  hr {
    margin: 50px auto;
  }

  @media (max-width: 991px) {
    top: 0;
    margin: 0 -15px;

    hr {
      margin: 10px auto 30px;
    }
  }
`;

// const Avatar = styled.div`
//   height: 80px;
//   width: 80px;
//   background-color: ${props => props.color || 'rgb(31, 130, 224)'};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
// `;

// const AvatarUser = styled(Avatar)`
//   width: 120px;
//   height: 120px;
//   > img {
//     border: 1px solid ${props => props.color || 'rgb(220, 220, 220)'};
//     width: 120px;
//     height: 120px;
//     border-radius: 50%;
//     display: block;
//     position: relative;
//     z-index: 1;

//     @media (max-width: 991px) {
//       width: 100%;
//       height: 100%;
//     }
//   }

//   @media (max-width: 991px) {
//     margin: 20px auto;
//   }
// `;

// const AvatarTitle = styled.p`
//   font-size: 1.4em;
//   color: rgb(255, 255, 255);
//   font-family: Gotham-Book;
//   margin: 0;
//   text-transform: uppercase;
// `;

// const NameReviews = styled.h4`
//   font-family: Gotham-Book;
//   color: ${props => props.color || 'rgb(31, 130, 224)'};
//   margin: 0;

//   @media (max-width: 991px) {
//     text-align: center;
//   }
// `;

// const SpecialityReviews = styled.h5`
//   font-family: Gotham-Book;
//   color: ${props => props.color || 'rgb(31, 130, 224)'};
//   margin: 0;
//   opacity: .4;

//   @media (max-width: 991px) {
//     text-align: center;
//     margin: 5px 0 15px;
//   }
// `;

// const FlexRowCenter = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;

//   @media (max-width: 991px) {
//     flex-direction: column;
//     justify-content: center;
//   }
// `;

const LinkS = styled(Link)`
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  font-family: Gotham-Book;
  font-size: 1.2em;

  &:hover {
    text-decoration: none;
    opacity: 0.6;
  }
`;

class UpcomingApptMsgModal extends Component {
  constructor() {
    super();
    this.state = {
      /*eslint-disable*/
      children: <div>default</div>,
      /* eslint-enable */
    };
  }

  //   renderProviderAvatar = (provider, fontcolor) => {
  //     if (provider.photo) {
  //       return <img color={fontcolor} alt="" src={provider.photo} />;
  //     }
  //     if (Object.keys(provider).length > 0) {
  //       const intialFirstname = provider.firstName.charAt(0);
  //       const intialLastname = provider.lastName.charAt(0);
  //       const initials = `${intialFirstname} ${intialLastname}`;
  //       return (
  //         <AvatarTitle>
  //           {initials}
  //         </AvatarTitle>
  //       );
  //     }
  //     return false;
  //   };

  //   renderProviderInfo = (provider, themeColor, fontcolor) =>
  //     (<Row>
  //       <Col xs={12} sm={10} smOffset={1} md={10} mdoffset={1}>
  //         <Col xs={12}>
  //           <FlexRowCenter>
  //             <Col xsHidden sm={3}>
  //               <AvatarUser color={themeColor}>
  //                 {this.renderProviderAvatar(provider, fontcolor)}
  //               </AvatarUser>
  //             </Col>
  //             <Col xsHidden sm={9}>
  //               <NameReviews color={themeColor}>
  //                 {`${provider.firstName} ${provider.lastName}`}
  //               </NameReviews>
  //               <br />
  //               <SpecialityReviews
  //                 color={themeColor}
  //               >{`${provider.specialty}`}</SpecialityReviews>
  //             </Col>
  //           </FlexRowCenter>
  //         </Col>
  //       </Col>
  //     </Row>);

  renderMsg = (upcomingApptService, fontcolor, history) => (
    <Row>
      <Col xs={12} sm={10} smOffset={1} md={10} mdoffset={1}>
        <MessageBody fontcolor={fontcolor}>
          You have already requested an appointment for this
          {' '}
          {upcomingApptService}
          {' '}
          service! Please, click here to review the
          <LinkS
            to="/upcomingApps"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: '/upcomingApps',
                search: sessionStorage.getItem('_ga'),
              });
            }}
          >
            {' '}
            Upcoming appointments
          </LinkS>
        </MessageBody>
      </Col>
    </Row>
  );

  render() {
    const { upcomingApptService, fontcolor, history } = this.props;
    return (
      <ModalS
        aria-labelledby="contained-modal-title-sm"
        backdrop="static"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <ModalHeader closeButton />
        <ModalBody>
          {/* {this.renderProviderInfo(
            providerInfo,
            themeColor,
            fontcolor,
          )} */}
          {this.renderMsg(upcomingApptService, fontcolor, history)}
        </ModalBody>
      </ModalS>
    );
  }
}
UpcomingApptMsgModal.propTypes = {
  // title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  upcomingApptService: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

UpcomingApptMsgModal.defaultProps = {
  title: 'Modal header',
};

export default UpcomingApptMsgModal;
