import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RootDiv = (props) => `
  background:${props.themeColor || '#258ADA'};
  padding: 30px 0;
  color:  ${props.color || 'rgb(0, 0, 0)'};
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  max-width: ${(props) => props.maxWidth};
`;

export const RootContainer = styled.div`
  height: auto;
  padding: 20px 40px;
  width: 100%;
  min-height: 429px;
  background: rgb(255, 255, 255);

  @media (max-width: 1199px) {
    padding: 20px 0;
  }

  @media (max-width: 991px) {
    padding: 0 0 20px;
  }
`;

export const PageContainer = styled.div`
  display: block;
`;

export const FlexBlock = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    margin: 0 -15px;
  }
`;

export const FormControl = (props) => `
  color: ${props.fontcolor || 'rgba(0, 0, 0, .8)'};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #AAAAAA;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  background: #FFFFFF;
  transition: border-color .3s ease;

  @media (max-width: 991px) {
    font-size: 1.1em;
  }

  &::placeholder {
    font-size: 1em;
    font-family: 'Roboto';
  	font-style: normal;
  	font-weight: 400;
    color: ${props.fontcolor || '#AAAAAA'};
    opacity: .7;

    @media (max-width: 991px) {
      font-size: 0.9em;
    }
  }

  &:focus {
    outline: none;
    border-color: ${props.colorsucses || '#258ADA'};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }

  ${props.disabled
  && `
  cursor: not-allowed;
  border-color: rgba(0, 0, 0, .2);
  color: rgba(0, 0, 0, .2);`}

  &[type="reset"] {
    font-family: Roboto;
    color: ${props.fontcolor || 'rgb(0, 0, 0)'};
    opacity: .4;
    border: none;
    padding-left: 0;

    &:hover {
      opacity: 1;
      color: ${props.colorsucses || '#258ADA'};
    }
  }
`;

export const LeftArrowBlock = styled.div`
  position: absolute;
  width: 85px;
  top: 25px;
  z-index: 1;

  a {
    color: ${(props) => props.fontcolor};
    width: 100%;
  }

  a:focus,
  a:hover {
    color: ${(props) => props.colorsucses || 'rgba(0, 0, 0, .8)'};
  }

  @media (max-width: 991px) {
    top: 54px;
  }

  @media (max-width: 991px) {
    top: 25px;
  }
`;

export const LinkArrows = styled(Link)`
  border: 2px solid ${(props) => props.color || '#258ADA'};
  background: ${(props) => props.color || '#258ADA'};
  opacity: 0.8;
  font-size: 1.3em;
  text-transform: capitalize;
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 20px;

  @media (max-width: 991px) {
    margin: 0;
  }

  @media (max-width: 991px) {
    font-size: 1.1em;
  }

  &:before,
  &:after {
    border-right: 2px solid rgb(0, 0, 0);
    opacity: 0.2;
    content: '';
    display: block;
    height: 20px;
    margin-top: -17px;
    position: absolute;
    transform: rotate(135deg);
    right: 0;
    top: 50%;
    width: 0;
  }

  &:after {
    margin-top: -3px;
    transform: rotate(45deg);
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: #258ada;
    opacity: 1;

    &:before,
    &:after {
      opacity: 0.4;
    }
  }
`;

export const LeftArrow = styled(LinkArrows)`
  color: ${(props) => props.color || '#258ada'} !important;
  border: 1px solid ${(props) => props.color || '#258ada'};
  background: transparent;
  &:before,
  &:after {
    left: 0;
  }

  &:before {
    transform: rotate(-135deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ChangeRoot = styled.div`
  display: flex;
  justify-content: CENTER;
  align-items: center;
  gap: 50px;

  a {
    font-size: 1.1rem;
    font-family: Roboto;
    border-bottom: 0px solid;
    text-decoration: none;
    padding-bottom: 15px;
    color: ${(props) => props.fontcolor || '#AAAAAA'};
    border-color: ${(props) => props.fontcolor || '#258ADA'};

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${(props) => props.linkHoverColor || '#424242'};
      border-color: ${(props) => props.linkHoverColor || '#258ADA'};
    }

    &.active {
      border-bottom: 3px solid;
      text-decoration: none;
      color: ${(props) => props.linkHoverColor || '#424242'};
      border-color: ${(props) => props.linkHoverColor || '#258ADA'};
    }
  }

  hr {
    border-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 991px) {
    text-align: left;
    width: 100%;
    left: 0;
    margin-left: 0;

    a {
      margin-right: 15px;
    }
  }
`;

ChangeRoot.displayName = 'ChangeRoot';

export const NotificationsStyles = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: '10px 5px 2px 1px',
      border: 'none',
    },
    success: {
      background: 'rgb(92, 184, 92)',
    },
    warning: {
      background: 'rgb(240, 173, 78)',
    },
    error: {
      background: 'rgb(201, 48, 44)',
    },
  },
  Title: {
    success: {
      color: 'rgb(255, 255, 255)',
    },
    warning: {
      color: 'rgb(255, 255, 255)',
    },
    error: {
      color: 'rgb(255, 255, 255)',
    },
  },
};

export const ClientHeightBlock = styled.div`
  display: flex;
  background: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  height: ${(props) => window.innerHeight - props.contentDefaultHeight}px;
`;
