import styled from 'styled-components';

export const MemberPhoto = styled.img`
  height: 70px;
  border-radius: 70px;
  margin: 0 -15px;
`;

export const MemberName = styled.p`
  color: ${(props) => props.colorDescription || 'rgba(0, 0, 0, 1)'};
  opacity: 1;
  font-family: Gotham-Medium;
  font-size: 1.2em;
  margin: 0;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const MemberDescription = styled.p`
  color: ${(props) => props.colorDescription || 'rgba(0, 0, 0, 1)'};
  opacity: 1;
  font-family: Gotham-Book;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonSmall = styled.button`
  padding: 5px;
  outline: none;
  background: none;
  border: none;
  color: ${(props) => props.color};
`;

export const ButtonSmallRight = styled.button`
  padding: 5px;
  outline: none;
  background: none;
  border: none;
  color: ${(props) => props.color};
`;

export const ButtonSmallSuccess = styled(ButtonSmall)`
  color: ${(props) => props.color};
`;

export const Members = styled.div`
  button {
    opacity: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    @media (max-width: 2000px) {
      opacity: 0.7;
    }
  }
  text-align: right;
  align-items: center
&:hover,
&:focus {
    p {
      opacity: 0.7;
    }
    button {
      opacity: 1;
    }
  }
`;

export const DivMembers = styled.div`
boder: '2px'
paddingLeft: '10 px'
paddingRight: '120 px'
`;

export const Forms = styled.form`
  @media (max-width: 991px) {
    margin: 20 0px;
  }
`;

export const ProfileMemberWrap = styled.div`
  padding: 15px 0;
`;
