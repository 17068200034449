import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Button from '../forms/Button';
import {
  login,
  resetPassModalShow,
  resetPassModalClose,
  resetPassword,
  validateResetPassword,
  resetPassConfirmModalClose,
  passwordSuccessModalClose,
} from '../../actions/authActions';
import FormElem from '../forms/FormElem';
import Loading from '../Loading';
import { clearErrors } from '../../actions/homeActions';
import { Root } from '../../elements/SignInForms';
import SignInFormModal from '../../elements/modals/SingInForm/SignInFormModal';
/*eslint-disable*/
class SignIn extends Component {
  constructor() {
    super();
    this.resetPassModalClose = this.resetPassModalClose.bind(this);
    this.updatePassModalClose = this.updatePassModalClose.bind(this);
    this.passSuccessMsgClose = this.passSuccessMsgClose.bind(this);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errors: {},
      isLoading: false,
      updatePasswordErrors: {
        email: '',
        password: '',
        pin: '',
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.resetPassModalClose = this.resetPassModalClose.bind(this);
    this.updatePassModalClose = this.updatePassModalClose.bind(this);
    this.passSuccessMsgClose = this.passSuccessMsgClose.bind(this);
    this.onValidateEmail = this.onValidateEmail.bind(this);
    this.onValidateAccountSubmit = this.onValidateAccountSubmit.bind(this);
    this.onRedirectLogin = this.onRedirectLogin.bind(this);
    this.attachRef = (target) => this.setState({ target });

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updatePasswordErrors !== this.props.updatePasswordErrors) {
      this.setState({ updatePasswordErrors: nextProps.updatePasswordErrors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const formData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.dispatch(login(formData, this.props.history));
  }

  onChange(value, name) {
    this.setState({ [name]: value });
  }

  onResetPassword(e) {
    e.preventDefault();
    this.setState({
      updatePasswordErrors: {
        ...this.state,
        password: '',
        validationPin: '',
        email: '',
      },
    });
    this.props.dispatch(resetPassModalShow());
  }

  onValidateEmail = (e) => {
    this.setState({ validateEmail: e });
  };

  onValidateAccountSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.validateEmail,
      method: 'email',
    };
    this.props.dispatch(resetPassword(data));
    this.props.dispatch(resetPassModalClose());
  };

  onNewPassword = (e) => {
    this.setState({ newPassword: e });
  };

  onConfirmCode = (e) => {
    this.setState({ confirmCode: e });
  };

  onRedirectLogin(e) {
    e.preventDefault();
    this.props.dispatch(passwordSuccessModalClose(this.props.history));
  }

  onConfirmCodeSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.validateEmail,
      password: this.state.newPassword,
      validationPin: this.state.confirmCode,
    };
    this.props.dispatch(validateResetPassword(data));
  };

  resetPassModalClose = () => {
    this.setState({
      updatePasswordErrors: {
        ...this.state,
        email: '',
      },
    });
    this.props.dispatch(resetPassModalClose());
  };

  updatePassModalClose = () => {
    this.props.dispatch(resetPassConfirmModalClose());
  };

  passSuccessMsgClose = () => {
    this.props.dispatch(passwordSuccessModalClose(this.props.history));
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  renderIsAuthorized(
    themeColor,
    fontcolor,
    resetPassModal,
    resetPassConfirmModal,
    passSuccessMessageModal,
    updatePasswordErrors,
  ) {
    const { email, password } = this.state;
    const _ga = sessionStorage.getItem('_ga')
      ? sessionStorage.getItem('_ga')
      : '';
    const { showPassword } = this.state;
    return (
      <Root>
        <SignInFormModal
          title="Trouble signing in?"
          message={`Enter your recovery email. You will receive a pin code
              used to validate your account.`}
          label="Email"
          size="sm"
          type="email"
          onChange={(e) => this.onValidateEmail(e)}
          onSubmit={this.onValidateAccountSubmit}
          errors={updatePasswordErrors}
          show={resetPassModal}
          onHide={this.resetPassModalClose}
          fontcolor={fontcolor}
          placeholder="Enter your email."
        />
        <SignInFormModal
          title="Submit your new password"
          message="Please use the confirmation code you received in your email."
          label="Password"
          size="sm"
          type="password"
          onChange={(e) => this.onNewPassword(e)}
          onSubmit={this.onConfirmCodeSubmit}
          errors={updatePasswordErrors}
          show={resetPassConfirmModal}
          onHide={this.updatePassModalClose}
          pinInput={1}
          onConfirmCode={(e) => this.onConfirmCode(e)}
          fontcolor={fontcolor}
          placeholder="Enter new password."
        />
        <SignInFormModal
          title="Congrats!"
          message={`You have successfuly reset your password.
              Close this message to be redirected to the Login page.`}
          size="sm"
          show={passSuccessMessageModal}
          onHide={this.passSuccessMsgClose}
          succesSection={1}
          onRedirectLogin={this.onRedirectLogin}
          successButton="Close"
          fontcolor={fontcolor}
        />
        {!resetPassModal
        && !resetPassConfirmModal
        && !passSuccessMessageModal ? (
          <div className="px-4 pt-4">
            <Row>
              <>
                <Row>
                  <Col xs={12}>
                    <p> Please login to schedule your appointment online.</p>
                  </Col>
                  <br />
                </Row>

                <Col xs={12}>
                  <form action="" onSubmit={this.onSubmit} className="p-4">
                    <div className="mb-4">
                      <FormElem
                        type="text"
                        valid={[
                          {
                            name: 'noEmpty',
                            message: this.errors ? this.errors.email : '',
                          },
                        ]}
                        name="email"
                        onChange={(value, name, type) => this.onChange(value, name, type)}
                        value={email}
                        placeholder="Enter email here"
                        labelContent="Email Address"
                      />
                    </div>
                    <div className="mb-2">
                      <FormElem
                        type={showPassword ? 'text' : 'password'}
                        valid={[
                          {
                            name: 'noEmpty',
                            message: this.errors ? this.errors.password : '',
                          },
                        ]}
                        name="password"
                        onChange={(value, name, type) => this.onChange(value, name, type)}
                        value={password}
                        placeholder="Enter password here"
                        labelContent="Password"
                      />
                    </div>
                    <div className="mb-5 text-start py-1">
                      <label className="showPassword d-block">
                        <input
                          type="checkbox"
                          checked={showPassword}
                          onChange={this.handleCheckboxChange}
                        />
                        <span>Show password</span>
                      </label>
                      <a
                        href=""
                        onClick={this.onResetPassword}
                        className="forgotPassword d-block mt-4"
                        style={{ color: themeColor }}
                      >
                        Forgot password?
                      </a>
                    </div>
                    <Button type="submit">Sign In</Button>
                    <div className="my-4 d-flex justify-content-center align-items-center gap-2">
                      <span>New patient?</span>
                      {' '}
                      <a
                        className="fw-bold text-decoration-none"
                        href=""
                        style={{ color: themeColor }}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: '/registry',
                            search: sessionStorage.getItem('_ga'),
                          });
                          dispatch(clearErrors());
                        }}
                      >
                        Register Now
                      </a>
                    </div>
                  </form>
                </Col>

                <Col xs={12} />
              </>
            </Row>
          </div>
          ) : null}
      </Root>
    );
  }

  render() {
    const {
      themeColor,
      authorization,
      fontcolor,
      isAuthorized,
      resetPassModal,
      resetPassConfirmModal,
      passSuccessMessageModal,
    } = this.props;
    const { updatePasswordErrors } = this.state;
    if (authorization) {
      return (
        <Root style={{ paddingTop: '45px' }}>
          <Loading themeColor={themeColor} innerText="loading..." />
        </Root>
      );
    }
    if (!isAuthorized) {
      return this.renderIsAuthorized(
        themeColor,
        fontcolor,
        resetPassModal,
        resetPassConfirmModal,
        passSuccessMessageModal,
        updatePasswordErrors,
      );
    }
    const _ga = sessionStorage.getItem('_ga')
      ? sessionStorage.getItem('_ga')
      : '';
    return <Redirect to={`/profile${_ga}`} />;
  }
}
SignIn.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  authorization: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  history: PropTypes.object.isRequired,
  resetPassModal: PropTypes.bool.isRequired,
  resetPassConfirmModal: PropTypes.bool.isRequired,
  passSuccessMessageModal: PropTypes.bool.isRequired,
  updatePasswordErrors: PropTypes.object.isRequired,
  sendtitle: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isAuthorized: state.userReducer.isAuthorized,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
  errors: state.homeReducer.errors,
  authorization: state.userReducer.authorization,
  resetPassModal: state.updatePasswordReducer.resetPassModal,
  resetPassConfirmModal: state.userReducer.resetPassConfirmModal,
  passSuccessMessageModal: state.userReducer.passSuccessMessageModal,
  wrongConfirmCodeToolTip: state.userReducer.wrongConfirmCodeToolTip,
  updatePasswordErrors: state.updatePasswordReducer.errors,
});

export default withRouter(connect(mapStateToProps)(SignIn));
