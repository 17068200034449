import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import InputDate from './InputDate';
import FormElem from './FormElem';
import {
  formatPhoneNumber,
} from './utils';
import {
  Title,
} from '../../elements/SignUpForms';

function UserInfoFields({
  data,
  onChange,
  fontcolor,
  themeColor,
  handleDayChange,
  errors,
}) {
  return (

    <div>
      <Row>
        <Col xs={12}>
          <Row>
            <Title className="forms-label" fontcolor={fontcolor}>
              Personal Information
            </Title>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6}>
              <div id="firstNameError" tabIndex="-1" />
              <FormElem
                valid={[
                  {
                    name: 'noEmpty',
                    message: errors.firstname || errors.firstName,
                  },
                ]}
                onChange={onChange}
                name="firstName"
                value={data.firstName}
                type="text"
                placeholder="First Name"
              />
            </Col>
            <Col xs={12} sm={6}>
              <div id="lastNameError" tabIndex="-1" />
              <FormElem
                valid={[
                  {
                    name: 'noEmpty',
                    message: errors.lastname || errors.lastName,
                  },
                ]}
                onChange={onChange}
                value={data.lastName}
                name="lastName"
                type="text"
                placeholder="Last Name"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <InputDate
                fontcolor={fontcolor}
                value={data.birthday}
                defaultValue={data.birthday}
                onDayChange={handleDayChange}
                themeColor={themeColor}
                errors={errors.birthday}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Title className="forms-label" fontcolor={fontcolor}>
          Contact information
        </Title>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <FormElem
            valid={[
              {
                name: 'noEmpty',
                message: errors.email,
              },
            ]}
            onChange={onChange}
            name="email"
            type="email"
            defaultValue={data.email}
            value={data.email}
            placeholder="Email"
            trim
          />
        </Col>
        <Col xs={12} sm={6}>
          <FormElem
            valid={[
              {
                name: 'noEmpty',
                message: errors.phone,
              },
            ]}
            onChange={onChange}
            name="phone"
            maxLength={14}
            value={formatPhoneNumber(data.phone)}
            placeholder="Phone, e.g. (123) 456-7890"
          />
        </Col>
      </Row>
    </div>
  );
}

UserInfoFields.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fontcolor: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  handleDayChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

UserInfoFields.defaultProps = {
  column: false,
};

export default UserInfoFields;
