/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import noData from '../../assests/images/noappotmient.svg';
import { FaPencilAlt } from 'react-icons/fa';
import { getUpcomingApps } from '../../actions/profileActions';
import Loading from '../Loading';
import {
  ButtonSmall,
  ButtonSmallRight,
} from '../../elements/profile/ProfileMembers';
import { calendarIcon } from '../icons/userIcons';
import SmallModal from '../SmallModal';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ViewUpcomingSchedule({
  fetchingUpcomingApps, fontcolor, themeColor, upcomingApps, token,
}) {
  const [smShow, setSmShow] = useState(false);

  const handleClose = () => setSmShow(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpcomingApps(token));
  }, []);

  const getTableTitle = (key) => {
    let title;

    switch (key) {
      case 'fromAp':
        title = 'Scheduled by Offices';
        break;
      case 'fromOls':
        title = 'Scheduled by OLS';
        break;
      default:
        title = 'Last Appointment';
        break;
    }

    return title;
  };

  const editAppointment = () => {
    setSmShow(true);
  };

  const renderAppsList = (fontcolor, themeColor, upcomingApps, smShow) => (
    <div>
      {upcomingApps
          && Object.keys(upcomingApps).map((list) => (
            <Row key={list}>
              <Col xs={12}>
                <p className="forms-label">{getTableTitle(list)}</p>
                <hr/>
              </Col>
              <Row>
                <Col xs={12}>
                  <Modal show={smShow} onHide={handleClose} backdrop="static">
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>If you need to cancel or reschedule your appointment for any reason, '
                      please call our office at your earliest convenience.</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <SmallModal
                    title={
                      'If you need to cancel or reschedule your appointment for any reason, ' +
                      'please call our office at your earliest convenience.'
                    }
                    show={smShow}
                    onHide={handleClose}
                    backdrop={'static'}
                  >
                    <div>
                      <ButtonSmall color={fontcolor} onClick={handleClose}>
                        OK
                      </ButtonSmall>
                    </div>
                  </SmallModal>
                  <div className="appointments">
                    {upcomingApps[list] && upcomingApps[list].length > 0 ? (
                    upcomingApps[list].map((app) => (
                      app.practice?.practiceName && (
                        <div className="appointment-card mb-4 p-4" key={`${list}_${app.id}`}>
                          <Row className='mb-4 align-items-center'>
                            <Col>
                              <div className='date'>
                                <div className='d-flex align-items-center gap-2' style={{ color: themeColor }}>
                                  <span>{calendarIcon(themeColor)}</span>
                                  <span className='date-info'>{moment(app.date, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a | MMM D, YYYY, ')}</span></div>
                            </div>
                            </Col>
                            <Col>
                              <div className='text-end'>
                                {list === 'fromAp' || list === 'fromOls' ? (
                                  <ButtonSmallRight color={fontcolor} onClick={editAppointment}>
                                    <FaPencilAlt />
                                  </ButtonSmallRight>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className='mb-4'>
                                <span className='labels-data'>Patient</span>
                                <p className='labels-text'> {`${app.patient.firstName} ${app.patient.lastName}`}</p>
                              </div>
                              <div>
                                <span className='labels-data'>Provider</span>
                                <p className='labels-text'> {`${app.provider.firstName} ${app.provider.lastName}`}</p>
                              </div>
                            </Col>
                            <Col>
                              <div className='mb-4'>
                                <span className='labels-data'>Office</span>
                                <p className='labels-text'> {`${app.practice.clinicName}`}</p>
                              </div>
                              <div>
                                <span className='labels-data'>Address</span>
                                <p className='labels-text'> {`${app.practice.practiceAddress1}`}
                                  {` ${app.practice.practiceAddress2}`}
                                  {`, ${app.practice.practiceCity}`}
                                  {`, ${app.practice.practiceState}`}
                                  {`, ${app.practice.practiceZip}`}
                                </p>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <span className='labels-data'>Reason</span>
                                <p className='labels-text'> {`${app.service.description}`}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                          )
                        ))
                      ) : (
                          renderSmallMessage(themeColor)
                    )}
                  </div>
                </Col>
              </Row>
            </Row>
          ))}
    </div>
  );

  const renderMessage = (themeColor) => (
    <Loading
      innerText="Searching for existing appointments."
      themeColor={themeColor}
    />
  );

  const renderSmallMessage = (themeColor) => {
    return (
      <div className='text-center'>
        <img width="160px" className='m-auto' src={noData} alt='no data' />
        <p style={{color: themeColor}}>Searching for existing appointments. </p>
      </div>
      )
  }

  if (fetchingUpcomingApps) {
    return (
      <div style={{ paddingTop: '45px' }}>
        <Loading themeColor={themeColor} innerText="loading..." />
      </div>
    );
  }

  return (
    <div className='view-upcoming-schedule'>
      {Object.keys(upcomingApps).length > 0 ? (
        renderAppsList(fontcolor, themeColor, upcomingApps, smShow)
      ) : (
        renderMessage(themeColor)
      )}
    </div>
  );
}

ViewUpcomingSchedule.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  upcomingApps: PropTypes.object,
  fetchingUpcomingApps: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
  upcomingApps: state.userReducer.upcomingApps,
  token: state.userReducer.token,
  fetchingUpcomingApps: state.userReducer.fetchingUpcomingApps,
  accountId: state.userReducer.id,
});

export default connect(mapStateToProps)(withRouter(ViewUpcomingSchedule));
