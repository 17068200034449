import React, { Component } from 'react';
import { MdCheckCircle, MdCancel, MdRemoveCircle } from 'react-icons/md';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../forms/Button';
import { FlexBlock, RootContainer } from '../styles';
import { approveRequest } from '../../actions/homeActions';
import Loading from '../Loading';

const DivCentered = styled.div`
  display: flex;
  flex: 1;
  width: auto;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  > form {
    width: 100%;
    text-align: center;
  }
`;

const H2 = styled.h2`
  margin: 50px auto 50px;
  position: absolute;
  justify-content: center;
  height: 40px;
`;

const WarningMess = styled.h2`
  font-size: 1.5em;
  font-family: Roboto;
  line-height: 1.2;
  width: 200%;
  text-align: center;
  justify-content: center;
  color: ${(props) => props.fontcolor || 'rgb(255,0,0)'};
`;

const SuccessMess = styled.h2`
  font-size: 1.5em;
  font-family: Roboto;
  line-height: 1.2;
  width: 200%;
  text-align: center;
  justify-content: center;
  color: ${(props) => props.fontcolor || 'rgb(0,0,255)'};
`;

const ResultMessage = styled.h2`
  text-align: center;
  font-size: 1em;
  font-family: Gotham-Book;
  line-height: 1.2;
  width: 200%;
  align-items: center;
`;

class ApproveAppointment extends Component {
  constructor() {
    super();
    this.state = {
      /*eslint-disable*/
      token: '',
      /* eslint-enable */
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      token: this.props.externalToken,
    };
    this.props.dispatch(approveRequest(data));
  };

  render() {
    const {
      user,
      errors,
      fetching,
      themeColor,
      fontcolor,
      fetched,
      apptConfirmed,
    } = this.props;
    const { firstName, lastName } = user;
    const resultMsg = apptConfirmed
      ? 'The appointment has been successfully created'
      : `${
        'The desired time could already be taken.\n'
          + 'Please, try to schedule the appointment manually for patient '
      } ${firstName} ${lastName}`;

    if (fetching) {
      return (
        <RootContainer>
          <FlexBlock>
            <Loading
              themeColor={themeColor}
              innerText="Confirming appointment..."
            />
          </FlexBlock>
        </RootContainer>
      );
    }

    if (fetched) {
      return (
        <RootContainer>
          <FlexBlock>
            <DivCentered>
              <Col>
                {apptConfirmed ? (
                  <MdCheckCircle color={fontcolor} size="10em" />
                ) : (
                  <MdCancel color="#FF0000" size="10em" />
                )}
              </Col>
              <SuccessMess>
                {apptConfirmed ? 'Congratulations!' : 'Sorry!'}
              </SuccessMess>
              <ResultMessage>{resultMsg}</ResultMessage>
            </DivCentered>
          </FlexBlock>
        </RootContainer>
      );
    }
    if (errors) {
      return (
        <RootContainer>
          <FlexBlock>
            <DivCentered>
              <Col>
                <MdRemoveCircle color="#FF0000" size="10em" />
              </Col>
              <WarningMess>Error!</WarningMess>
              <ResultMessage>
                We seem to be experiencing system issues. Please try again
                later.
              </ResultMessage>
            </DivCentered>
          </FlexBlock>
        </RootContainer>
      );
    }

    return (
      <RootContainer>
        <FlexBlock>
          <Col xs={12} md={6} lg={6}>
            <DivCentered>
              <H2>Please, confirm the appointment</H2>
              <form action="" onSubmit={(e) => this.onSubmit(e)}>
                <Button styles="margin: 200px auto 0;" type="submit">
                  Confirm appointment
                </Button>
              </form>
            </DivCentered>
          </Col>
        </FlexBlock>
      </RootContainer>
    );
  }
}

ApproveAppointment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.bool,
  themeColor: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
  fetched: PropTypes.bool,
  externalToken: PropTypes.string,
  apptConfirmed: PropTypes.bool,
  fontcolor: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};
const mapStateToProps = (state) => ({
  user: state.userReducer,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  errors: state.appointmentApprovalReducer.errors,
  fetching: state.appointmentApprovalReducer.fetching,
  fetched: state.appointmentApprovalReducer.fetched,
  externalToken: state.homeReducer.externalToken,
  apptConfirmed: state.appointmentApprovalReducer.apptConfirmed,
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
});

ApproveAppointment.defaultProps = {
  errors: false,
  fetching: false,
  fetched: false,
  apptConfirmed: false,
  externalToken: false,
};

export default withRouter(connect(mapStateToProps)(ApproveAppointment));
