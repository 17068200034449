import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../components/styles';

export const Root = styled(PageContainer)`
  padding: 20px 40px;

  hr {
    margin-bottom: 45px;
  }

  @media (max-width: 991px) {
    padding: 0 0 20px;

    hr {
      margin-bottom: 25px;
      margin-top: 50px;
    }
  }
`;

export const Apointment = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 0 10px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    padding: 0 0 10px;
  }
`;

export const LinkS = styled(Link)`
  color: #fff;
  font-size: 1.2em;
  background: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const LinkEmail = styled.span`
  font-size: 1.2em;
  font-family: Gotham-Medium;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};

  @media (max-width: 991px) {
    font-size: 1.3em;
  }
`;

export const AppointmentTitle = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;
`;

export const AppointmentContent = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;

  @media (max-width: 991px) {
    margin: 0;
  }
`;

export const DescriptionContent = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 991px) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export const DescriptionText = styled.p`
  font-family: Gotham-Medium;
  font-size: 15px;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;
  line-height: 1.6;
  display: inline-block;
  width: 50%;
  margin-right: 15px;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  a {
    opacity: 1;
    color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  }
`;

export const ConfirmAppMsgContent = styled.div`
  display: flex;
  margin-top: 30px;
  border-color: 'red';
  @media (max-width: 500px) {
    margin-top: 40px;
    flex-direction: column;
  }
`;
export const WarningDescriptionText = styled.p`
  font-family: Gotham-Medium;
  font-size: 15px;
  color: ${(props) => props.fontcolor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;
  line-height: 1.6;
  display: inline-block;
  width: 50%;
  margin-right: 15px;
  border: 1;
  border-bottom: 2px solid rgb(255, 0, 0);
  border-left: 2px solid rgb(255, 0, 0);
  border-right: 2px solid rgb(255, 0, 0);
  border-top: 2px solid rgb(255, 0, 0);
  text-align: center;
  justify-content: center;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  a {
    opacity: 1;
    color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  }
`;
