import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import StepHeader from './AppointmentStepsHeader';
import AppointmentStepsHeaderNav from './AppointmentStepsHeaderNav';
import { getServices, selectService } from '../../actions/servicesActions';
import Loading from '../Loading';
import { Root } from '../../elements/SignInForms';
import UpcomingApptMsgModal from '../../elements/modals/Services/UpcomingApptMsgModal';
import StepsButton from './StepsButton';

function Services({
  themeColor,
  services,
  fontcolor,
  fetchingServices,
  phoneOffice,
  history,
  token,
  patientId,
  hashBid,
  dispatch,
}) {
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [showUpcomingAppt, setShowUpcomingAppt] = useState(false);
  const [upcomingApptService, setUpcomingApptService] = useState('');

  useEffect(() => {
    setSelectedServiceId(sessionStorage.getItem('serviceId'));
    const data = { patientId, hashBid };
    dispatch(getServices(token, data));
  }, [dispatch, token, patientId, hashBid]);

  const handleButtonClick = (serviceId) => {
    setSelectedServiceId(serviceId);
    const selectedService = findElem(services, serviceId);
    if (!selectedService.service.hasUpcoming) {
      const data = {
        service: {
          id: parseInt(selectedService.service.id, 0),
          pmsId: selectedService.service.pmsId,
          description: selectedService.service.description,
          duration: selectedService.service.duration,
          name: selectedService.service.description,
          scheduleLimit: selectedService.service.scheduleLimit,
        },
      };
      dispatch(selectService(data, history));
      history.push('/providers');
    } else {
      setShowUpcomingAppt(true);
      setUpcomingApptService(selectedService.service.description);
    }
    sessionStorage.setItem('serviceId', serviceId);
  };

  const upcomingApptMsgClose = () => {
    setShowUpcomingAppt(false);
    setUpcomingApptService('');
  };

  const findElem = (array, elemId) => {
    let findElems = {};
    array.forEach((elem) => {
      if (elem.service.id === elemId) {
        findElems = elem;
      }
    });
    return findElems;
  };

  const renderServices = () => services.map((service) => {
    const isCurrent = parseInt(selectedServiceId, 0) === service.service.id;
    return (
      <Col key={service.service.id} xs={12} sm={12} md={12} lg={6}>
        <div className="mb-2">
          <Row>
            <Col>
              <Button
                variant={isCurrent ? 'primary' : 'outline-primary'}
                onClick={() => handleButtonClick(service.service.id)}
                style={{
                  width: '100%',
                  backgroundColor: isCurrent ? themeColor : '#fff',
                  color: isCurrent ? '#fff' : fontcolor,
                  borderColor: themeColor,
                }}
              >
                {service.service.description}
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    );
  });

  const renderMessage = () => (
    <Loading
      innerText={`We are unable to complete your request online.
      Please call the office at ${phoneOffice} to schedule your appointment.`}
      themeColor={themeColor}
    />
  );

  if (fetchingServices) {
    return (
      <Root style={{ paddingTop: '45px' }}>
        <Loading themeColor={themeColor} innerText="loading..." />
      </Root>
    );
  }

  return (
    <div className="steps-container">
      <Row className="g-0 flex-shrink-0">
        <Col lg={3} className="appointment-steps-header-nav">
          <AppointmentStepsHeaderNav />
        </Col>
        <Col md={12} lg={9} sm={12}>
          <UpcomingApptMsgModal
            title="Upcoming appoinments"
            show={showUpcomingAppt}
            onHide={upcomingApptMsgClose}
            upcomingApptService={upcomingApptService}
            fontcolor={fontcolor}
            themeColor={themeColor}
            history={history}
          />
          <div className="main-content">
            <StepHeader
              title="Available Services"
              description="Please select the reason for your visit"
            />
            <Row>
              {services.length > 0 ? renderServices() : renderMessage()}
            </Row>

            <StepsButton />

          </div>
        </Col>
      </Row>
    </div>
  );
}

Services.propTypes = {
  dispatch: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  fetchingServices: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  phoneOffice: PropTypes.string.isRequired,
  hashBid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
  services: state.serviceReducer.services,
  fetchingServices: state.serviceReducer.fetchingServices,
  token: state.userReducer.token,
  phoneOffice: state.homeReducer.clinic.clinicInfo.phone,
  hashBid: state.appointmentReducer.request.hashBid,
  patientId: state.appointmentReducer.request.patientId,
});

export default withRouter(connect(mapStateToProps)(Services));
