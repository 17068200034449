import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fade, Row } from 'react-bootstrap';
import styled from 'styled-components';
import FormElem from '../../../components/forms/FormElem';

import Button from '../../../components/forms/Button';

const Message = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.fontcolor || '#424242'};
`;

// const Label = styled.label`
//     font-weight: 600;
//     display: block;
//     font-size: 12px;
//     color: ${props => props.fontcolor || 'rgb(0, 0, 0)'};
//     margin-bottom: 8px;
//     margin-top: 30px;
//     text-align: left;
// `;

// const FormInput = styled.input`
//     font-size: 14px;
//     height: 40px;
//     color: #282828;
//     border-radius: 3px;
//     box-shadow: none;
//     -moz-box-shadow: none;
//     -o-box-shadow: none;
//     -webkit-box-shadow: none;
//     border: 1px solid #d4d4d4;
//     padding: 8px;
//     display: block;
//     width: 100%;
//     line-height: 1.42857143;
//     background-color: #fff;
//     background-image: none;
//     margin: 0;
// `;

const ButtonModalDiv = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const FormModal = styled.form`
  @media (max-width: 991px) {
    margin: 0 -15px;
    display: block;
    text-align: left;
    margin-top: 10px;
  }
`;

class ConfirmCodeModal extends Component {
  constructor() {
    super();
    /*eslint-disable*/
    this.state = {
      children: <div>default</div>,
    };
    /* eslint-enable */
  }

  onChange = (e) => {
    this.props.onChange(e);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const {
      message, errors, label, type,
    } = this.props;
    return (
      this.props.show ? (
        <div>
          <Row className="text-center mb-4">
            <Message>{message}</Message>
          </Row>
          <Row>
            <FormModal onSubmit={(e) => this.onSubmit(e)}>
              <FormElem
                type={type}
                valid={[
                  {
                    name: 'noEmpty',
                    message: '',
                  },
                ]}
                onChange={(e) => this.onChange(e)}
                placeholder={label}
                labelContent={label}
                name="confirmCode"
              />
              <Fade in={errors} className="text-danger">
                <div id="error-confirm-code-text" className="mt-3">
                  <p>Wrong confirmation code!</p>
                  <p>Please verify and submit again</p>
                </div>
              </Fade>
              <ButtonModalDiv>
                <Button type="submit">Submit</Button>
              </ButtonModalDiv>
            </FormModal>
          </Row>
        </div>
      ) : null
    );
  }
}

ConfirmCodeModal.propTypes = {
  message: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  /*eslint-disable*/
  onHide: PropTypes.func.isRequired,
};

ConfirmCodeModal.defaultProps = {
  title: 'Modal header',
};

export default ConfirmCodeModal;
