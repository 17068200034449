import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LabelS = styled.label`
  cursor: pointer;
  color: ${(props) => props.fontcolor || '#258ADA'};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  user-select: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
  border-color:  ${(props) => props.fontcolor || '#258ADA'};
  width: 100px;
  margin-right: 5px;
  margin-bottom: 5px;
  position: relative;
  padding: 5px 5px;

  @media (max-width: 1199px) {
    margin-right: 10px;
  }

  @media (max-width: 991px) {
    width: 120px;
    margin-right: 0;
    padding: 10px 0;
  }

  &.select,
  &:hover,
  &:focus {
    background: ${(props) => props.colorsucses || 'rgba(31, 130, 224, .4)'};
    border-color: ${(props) => props.colorsucses || 'rgba(31, 130, 224, .4)'};
    color: #fff;
  }

  ${(props) => props.disabled
    && `
    opacity: .5;
    cursor: default;
  `};
`;

const InputS = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: 0;
  padding: 0;
  opacity: 0;
`;

function TimeCheckbox({
  themeColor,
  time,
  onChange,
  id,
  name,
  selectedTime,
  fontcolor,
}) {
  const isCurrent = selectedTime === id.toString();
  return (
    <div>
      <LabelS
        colorsucses={themeColor}
        fontcolor={fontcolor}
        className={isCurrent ? 'select' : null}
      >
        <InputS
          checked={selectedTime === id.toString()}
          type="radio"
          name={name}
          id={id}
          value={id}
          onChange={onChange}
        />
        {time}
      </LabelS>
    </div>
  );
}

TimeCheckbox.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectedTime: PropTypes.string, //eslint-disable-line
};

export default TimeCheckbox;
