import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Title = styled.h4`
  font-family: Roboto;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  color: ${(props) => props.fontcolor || '#424242'};
  opacity: 1;
  text-transform: capitalize;
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.fontcolor || '#424242'};
`;

function StepHeader({
  title, description, fontcolor, message,
}) {
  return (
    <>
      <Title fontcolor={fontcolor}>{title}</Title>
      <Description className={message ? 'mb-0' : ''} fontcolor={fontcolor}>{description}</Description>
      {message && <Description className="m-0 fw-bold" fontcolor="red">{message}</Description>}
    </>
  );
}

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  message: PropTypes.string,
};

const mapStateToProps = (state) => ({
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
});

export default connect(mapStateToProps)(StepHeader);
