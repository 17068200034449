import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import cookie from 'react-cookies';
import { resetApptRequest } from '../../actions/appointmentActions';
import getPatientInfo from '../../utils/requestInfoUtils';
import Loading from '../Loading';
import {
  LinkS,
} from '../../elements/appointmentSteps/Success';
import StepHeader from './AppointmentStepsHeader';
import approve from '../../assests/images/approve.svg';

const ProfileLinkHeader = styled(Col)`
  @media (max-width: 991px) {
    display: none;
  }
`;

const appointmentTitle = [
  'Request Made By:',
  'Request For:',
  'Reason:',
  'Appointment Selected:',
];

const id = ['madeBy', 'requestFor', 'reason', 'opening'];

class Success extends Component {
  renderAppointmentDescription(themeColor) {
    const sessionIdLogin = sessionStorage.getItem('sessionIdLogin');
    const isSessionIdLogin = !!sessionIdLogin; // Convert to boolean
    localStorage.clear();
    sessionStorage.clear();
    cookie.remove('token', { path: '/' });

    return (
      <div className="my-4">
        {!isSessionIdLogin && (
        <Row key={Math.random()} className="mt-5">
          <ProfileLinkHeader md={12} lg={12} sm={12}>
            <p style={{ color: themeColor }} className="m-0 fw-bold">
              Return to Profile
            </p>
          </ProfileLinkHeader>
          <Col md={12} lg={12} sm={12} className="mt-3">
            <LinkS
              style={{ backgroundColor: themeColor }}
              to="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push({
                  pathname: '/profile',
                  search: sessionStorage.getItem('_ga'),
                });
                this.props.dispatch(resetApptRequest());
              }}
            >
              Profile
            </LinkS>
          </Col>
        </Row>
        )}
      </div>
    );
  }

  renderMessage = (themeColor, phoneOffice) => (
    <Loading
      innerText={`We are unable to complete your request online.
      Please call the office at ${phoneOffice} to schedule your appointment.`}
      themeColor={themeColor}
    />
  );

  renderContent(fontcolor, patientName) {
    const week = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    const { firstName, lastName } = this.props;
    const { selectedService, selectedTime } = this.props.appointmentReducer;
    const { name, duration } = selectedService;
    const date = new Date(selectedTime.replace(/-/g, '/'));
    const time = moment(date, 'YYYY-MM-DD hh:mm:ss');
    return appointmentTitle.map((title, index) => {
      let content = '';
      switch (id[index]) {
        case 'madeBy':
          content = `${firstName} ${lastName}`;
          break;
        case 'requestFor':
          content = `${patientName}`;
          break;
        case 'reason':
          content = `${name} (${duration} min duration)`;
          break;
        case 'opening':
          content = `${week[time.day() - 1]}, ${time.format('MMMM DD YYYY')},
            ${time.format('hh:mm a')}`;
          break;
        default:
          break;
      }
      return (
        <div className="my-4">
          <Row key={Math.random()}>
            <Col md={3} lg={3} sm={12}>
              <p style={{ color: fontcolor }} className="m-0 fw-bold">{title}</p>
            </Col>
            <Col md={7} lg={7} sm={12}>
              <p className="m-0">{content}</p>
            </Col>
          </Row>
          <hr />
        </div>
      );
    });
  }

  render() {
    const {
      themeColor, request, fontcolor, familyMembers, phoneOffice,
    } = this.props;
    const { confirmedMsg } = this.props.appointmentReducer;
    const patientInfo = getPatientInfo(request.patientId, familyMembers);
    return (
      <div className="steps-container">
        <Row className="g-0 flex-shrink-0">
          <Col md={12} lg={12} sm={12}>
            <div className="main-content">
              <StepHeader
                title="Request Complete!"
                description="We have received your request and will be in touch shortly via the email address you provided. Thank you."
              />
              <Row className="mt-5">
                <div className="text-center mb-5 d-flex justify-content-center">
                  <img src={approve} alt="approve" />
                </div>
                {request.activePatient ? (
                  <Row>
                    <Col xs={12} smHidden mdHidden lgHidden>
                      {this.renderContent(themeColor, patientInfo.fullName)}
                      {this.renderAppointmentDescription(
                        themeColor,
                        patientInfo.email,
                        fontcolor,
                        confirmedMsg,
                      )}
                    </Col>
                  </Row>
                ) : null}
                {request.activePatient ? null : (
                  <Row>{this.renderMessage(themeColor, phoneOffice)}</Row>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
Success.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  appointmentReducer: PropTypes.shape({
    selectedService: PropTypes.object,
    selectedTime: PropTypes.string,
    confirmedMsg: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  familyMembers: PropTypes.array.isRequired,
  phoneOffice: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontcolor: state.homeReducer.clinic.styleOptions.fontColor,
  firstName: state.userReducer.accountInfo.firstName,
  lastName: state.userReducer.accountInfo.lastName,
  familyMembers: state.userReducer.familyMembers,
  appointmentReducer: state.appointmentReducer,
  request: state.appointmentReducer.request,
  phoneOffice: state.homeReducer.clinic.clinicInfo.phone,
});

export default withRouter(connect(mapStateToProps)(Success));
