import styled from 'styled-components';

export const RadioPad = styled.div`
  display: flex;
  gap: 1.3rem;
  width: 100%;
  padding: 2rem 1rem;
  background: none;
  margin-bottom: 20px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  min-height: 165px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px -1px rgb(140, 140, 140);
  }

  &.select {
    box-shadow: 0 0 5px -1px rgb(140, 140, 140);
  }
`;

export const RadioLabel = styled.label`
  border: 2px solid ${(props) => props.colorsucses || '#258ADA'};
  color: ${(props) => props.colorsucses || '#258ADA'};
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  width: 128px;
  border-radius: 50px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;

  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    color: #fff;
    border-color: ${(props) => props.colorsucses || '#258ADA'};
    background: ${(props) => props.colorsucses || '#258ADA'};
    opacity: 1;
  }

  &.select {
    color: #fff;
    border-color: ${(props) => props.colorsucses || '#258ADA'};
    background: ${(props) => props.colorsucses || '#258ADA'};
    opacity: 1;
  }
`;

export const RadioInput = styled.input`
  -webkit-appearance: none;
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
  opacity: 0;
`;

export const Title = styled.div`
  text-align: left;
`;

export const Name = styled.h2`
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.fontcolor || '#424242'};
  opacity: 0.8;
  margin: 10px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1199px) {
    font-size: 1.4em;
  }

  @media (max-width: 991px) {
    font-size: 1.3em;
  }

  @media (max-width: 991px) {
    font-size: 1.5em;
    margin: 0;
  }
`;

export const Profession = styled.h3`
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.fontcolor || '#424242'};
  opacity: 0.5;
  margin: 7px 0;
  // word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AvatarProvider = styled.img`
  width: 92px;
  height: 92px;
  border-radius: 50%;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 86px;
    height: 86px;
  }
`;

export const ReviewsCount = styled.div`
  color: ${(props) => props.color && ('#258ADA')};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    font-size: 1em;
  }
`;

export const ReviewsLink = styled(ReviewsCount)`
  color: ${(props) => props.color || '#258ADA'};
  &:hover {
    text-decoration: underline;
  }
`;

export const StarRating = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 7px;

  > div {
    display: block !important;
  }
  label {
    line-height: 1;
    margin-bottom: 2px;
  }

  @media (max-width: 1199px) {
    font-size: 13px;
  }
`;

export const Avatars = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    margin: 0 -15px;
  }
`;
