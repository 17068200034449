import styled from 'styled-components';
import Select from 'react-select';

export const Selector = styled(Select)`
  font-size: 25px;
  margin-top: 15px;
  .Select-clear-zone {
    display: none;
  }
  .Select-arrow {
    border-style: solid;
  }
  .Select-control {
    border-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    background-color: #f5f3f3;
  }
`;

export const SelectorOffice = styled(Select)`
  font-size: 20px;
  margin-top: 15px;
  .Select-clear-zone {
    display: none;
  }
  .Select-arrow {
    border-style: solid;
  }
  .Select-control {
    border-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    background-color: #f5f3f3;
  }
`;
export const ProfileBlock = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 80%;
    height: auto;
  }
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 230px;
`;

export const AddAvatarBtn = styled.button`
  background: rgb(255, 255, 255);
  border: 2px solid ${(props) => props.themeColor};
  border-radius: 50%;
  width: 40px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 5px;
  > svg {
    fill: ${(props) => props.themeColor};
  }
  &:hover {
    background: ${(props) => props.themeColor};
    border: 2px solid rgb(255, 255, 255);
    > svg {
      fill: rgb(255, 255, 255);
    }
  }
`;

export const InputRange = styled.input`
  height: 16px;
  margin-bottom: 20px;

  &[type='range'] {
    -webkit-appearance: none;
    border: 1px solid white;
  }
  &[type='range']::-webkit-slider-runnable-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${(props) => props.themeColor || '#39D2B4'};
    margin-top: -4px;
  }
  &[type='range']:focus {
    outline: none;
  }
  &[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  &[type='range']::-moz-range-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  &[type='range']::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${(props) => props.themeColor || '#39D2B4'};
  }

  &[type='range']:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  &[type='range']::-ms-track {
    height: 5px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }
  &[type='range']::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }
  &[type='range']::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  &[type='range']::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${(props) => props.themeColor || '#39D2B4'};
  }
  &[type='range']:focus::-ms-fill-lower {
    background: #888;
  }
  &[type='range']:focus::-ms-fill-upper {
    background: #ccc;
  }
`;

export const InputFile = styled.div`
  position: relative;

  label {
    display: block;
    padding: 14px 45px;
    background: ${(props) => props.colorsucses || '#39D2B4'};
    color: #fff;
    font-size: 1em;
    transition: all 0.4s;
    cursor: pointer;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }

  input:hover + label,
  input:focus + label,
  label:hover,
  label:focus {
    background: #34495e;
    color: ${(props) => props.colorsucses || '#39D2B4'};
  }
`;

export const ProfileName = styled.h4`
  font-family: Gotham-Book;
  font-size: 1.7em;
  margin-top: 0;
  margin-bottom: 15px;
  color: ${(props) => props.colorTitle || 'rgba(0, 0, 0, 1)'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const tableborders = styled.h1`
  font-family: Gotham-Book;
  font-size: 1.7em;
  margin-top: 0;
  margin-bottom: 15px;
`;

export const ProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  text-align: center;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`;

export const Description = styled.p`
  // font-family: Gotham-Book;
  font-size: 1em;
  margin-bottom: 15px;
  color: ${(props) => props.colorDescription || 'rgba(0, 0, 0, .8)'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditProfile = styled.span`
  cursor: pointer;
  font-size: 1em;
  display: inline-block;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  > svg {
    margin-right: 10px;
  }
`;

export const ScheduleAppt = styled.span`
  cursor: pointer;
  font-size: 1em;
  display: inline;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  > svg {
    margin-right: 5px;
  }
`;

export const AppsLink = styled.span`
  cursor: pointer;
  font-size: 1.2em;
  display: inline-block;
  float: none;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  > svg {
    margin-right: 0px;
  }
`;
export const ViewUpcomingApp = styled.span`
  cursor: pointer;
  font-size: 1em;
  display: contents;
  float: right;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  > svg {
    margin-right: 3px;
  }
  horizontalalign: 'right';
  text-align: 'right';

  > svg {
    margin-left: 0px;
  }
`;

export const PickPt = styled.span`
  cursor: pointer;
  font-size: 1em;
  display: inline-block;
  color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
  > svg {
    margin-right: 10px;
  }
  @media (max-width: 991px) {
    padding-top: 20px;
  }
`;
export const AddButton = styled.div`
  margin-top: 22px;
  clear: both;
  display: flex;
  justify-content: center;
  > button {
    padding: 5px;
    margin: 5px;
  }
  > :nth-child(2) {
    background: #ccc;
  }

  @media (max-width: 991px) {
    flex-direction: column;

    button {
      margin: 20px;
    }
  }
`;

export const Forms = styled.form`
  @media (max-width: 991px) {
  }
`;
