import {
  FETCH_CLINIC_FULFILLED,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_AUTH_FAILURE,
  USER_AUTH_KEY,
  USER_LOGOUT_SUCCESS,
  USER_REGISTRY_REQUEST,
  USER_REGISTRY_SUCCESS,
  USER_REGISTRY_FAILURE,
  ADD_USER_FAMILY_MEMBER_FULFILLED,
  EDIT_USER_FAMILY_MEMBER_FULFILLED,
  DELETE_USER_FAMILY_MEMBER_FULFILLED,
  EDIT_INFO_USER_FULFILLED,
  CHANGE_AVATAR_USER_FULFILLED,
  FETCH_USER_FAMILY_MEMBER_REQUEST,
  FETCH_USER_FAMILY_MEMBER_FULFILLED,
  FETCH_USER_FAMILY_MEMBER_REJECTED,
  EDIT_INFO_USER_REJECTED,
  ADD_USER_FAMILY_MEMBER_REJECTED,
  EDIT_USER_FAMILY_MEMBER_REJECTED,
  CHECK_USER_STATUS_REQUEST,
  CHECK_USER_STATUS_SUCCESS,
  CHECK_USER_STATUS_FAILURE,
  VALIDATE_METHOD_REQUEST,
  VALIDATE_METHOD_SUCCESS,
  VALIDATE_METHOD_FAILURE,
  VALIDATE_MODAL_SHOW,
  VALIDATE_MODAL_HIDE,
  VALIDATE_MODAL_CLOSE,
  CONFIRM_MODAL_SHOW,
  CONFIRM_MODAL_HIDE,
  CONFIRM_MODAL_CLOSE,
  CONFIRM_METHOD_REQUEST,
  CONFIRM_METHOD_SUCCESS,
  CONFIRM_METHOD_FAILURE,
  REGISTRY_COMPLETED_MESSAGE_SHOW,
  REGISTRY_COMPLETED_MESSAGE_HIDE,
  WRONG_PIN_MESSAGE_SHOW,
  WRONG_PIN_MESSAGE_HIDE,
  RESET_PASSWORD_CONFIRM_MODAL_SHOW,
  RESET_PASSWORD_CONFIRM_MODAL_HIDE,
  RESET_PASSWORD_CONFIRM_MODAL_CLOSE,
  PASSWORD_SUCCESS_MODAL_SHOW,
  PASSWORD_SUCCESS_MODAL_HIDE,
  FETCH_USER_ONCOMING_APPS_REQUEST,
  FETCH_USER_ONCOMING_APPS_FULFILLED,
  FETCH_USER_ONCOMING_APPS_REJECTED,
  USER_REGISTRY_VALIDATE_PROCESS_START,
  EXISTING_ACCOUNT_MESSAGE_HIDE,
  FETCH_USER_ACCOUNT_INFO_FULFILLED,
  AUTHORIZE_USER,
  SET_PATIENT_REQUEST,
  UTM_PARAMETERS,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_CLINIC_FULFILLED: {
      return {
        ...state,
        isAuthorized: false,
      };
    }

    case USER_AUTH_REQUEST: {
      return {
        ...state,
        authorization: true,
        authorized: false,
        isAuthorized: false,
      };
    }

    case USER_AUTH_SUCCESS: {
      // const data = action.payload.data.item.account;
      return {
        ...state,
        token: action.payload.data.item.token,
        familyMembers: [],
        upcomingApps: [],
        createdBy: 0,
        isUnder18: false,
        fetchingUpcomingApps: false,
        authorization: false,
        authorized: true,
        isAuthorized: true,
      };
    }

    case USER_AUTH_FAILURE: {
      return {
        ...state,
        authorization: false,
        authorized: false,
      };
    }

    case USER_REGISTRY_REQUEST: {
      return {
        ...state,
        registering: true,
        registered: false,
      };
    }

    case USER_REGISTRY_SUCCESS: {
      return {
        ...state,
        registering: false,
        registered: true,
      };
    }

    case USER_REGISTRY_FAILURE: {
      return {
        ...state,
        registering: false,
        registered: false,
      };
    }

    case USER_AUTH_KEY: {
      return {
        ...state,
        token: action.payload,
        authorization: false,
        authorized: true,
        isAuthorized: localStorage.getItem('token') !== '',
      };
    }

    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        errors: {},
        authorization: false,
        authorized: false,
        isAuthorized: false,
        token: '',
        id: null,
        patientId: null,
        firstName: null,
        lastName: null,
        email: null,
        birthday: null,
        photo: null,
        phone: null,
        isUnder18: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null,
        familyMembers: null,
        upcomingApps: null,
        fetchingUpcomingApps: false,
        createdBy: null,
      };
    }

    case FETCH_USER_FAMILY_MEMBER_REQUEST: {
      return {
        ...state,
        fetchingFamilyMembers: true,
        fetchedFamilyMembers: false,
      };
    }

    case FETCH_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        fetchingFamilyMembers: false,
        fetchedFamilyMembers: true,
        familyMembers: action.payload.familyMembers,
        familyMembersOptions: action.payload.familyMembersOptions,
      };
    }

    case FETCH_USER_ACCOUNT_INFO_FULFILLED: {
      return {
        ...state,
        accountInfo: action.payload,
      };
    }

    case AUTHORIZE_USER: {
      return {
        ...state,
        isAuthorized: action.payload,
      };
    }

    case FETCH_USER_FAMILY_MEMBER_REJECTED: {
      return {
        ...state,
        fetchingFamilyMembers: false,
        fetchedFamilyMembers: false,
      };
    }

    case ADD_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        editSuccess: true,
      };
    }

    case DELETE_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
      };
    }

    case EDIT_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        editSuccess: true,
      };
    }

    case EDIT_INFO_USER_FULFILLED: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          birthdate: action.payload.birthday,
          email: action.payload.email,
          phone: action.payload.phone,
        },
        editSuccess: true,
      };
    }

    case EDIT_USER_FAMILY_MEMBER_REJECTED:
    case ADD_USER_FAMILY_MEMBER_REJECTED:
    case EDIT_INFO_USER_REJECTED: {
      return {
        ...state,
        editSuccess: false,
      };
    }

    case CHANGE_AVATAR_USER_FULFILLED: {
      return {
        ...state,
        photo: action.payload.data.item.photo_url,
      };
    }

    case CHECK_USER_STATUS_REQUEST: {
      return {
        ...state,
        verifying: true,
        verified: false,
      };
    }

    case CHECK_USER_STATUS_SUCCESS: {
      return {
        ...state,
        existingAccountMsgModal: true,
        verifying: false,
        verified: true,
      };
    }

    case CHECK_USER_STATUS_FAILURE: {
      return {
        ...state,
        verifying: false,
        verified: false,
      };
    }

    case VALIDATE_METHOD_REQUEST: {
      return {
        ...state,
        validating: true,
        validated: false,
      };
    }

    case VALIDATE_METHOD_SUCCESS: {
      return {
        ...state,
        validating: false,
        validated: true,
      };
    }

    case VALIDATE_METHOD_FAILURE: {
      return {
        ...state,
        validating: false,
        validated: false,
      };
    }

    case VALIDATE_MODAL_SHOW: {
      return {
        ...state,
        validateModal: true,
        userRegistryAccountId: action.payload.data.item.Account.id,
      };
    }

    case VALIDATE_MODAL_HIDE: {
      return {
        ...state,
        validateModal: false,
        validateMethod: action.payload.method,
      };
    }

    case VALIDATE_MODAL_CLOSE: {
      return {
        ...state,
        validateModal: false,
      };
    }

    case CONFIRM_METHOD_REQUEST: {
      return {
        ...state,
        confirming: true,
        confirmed: false,
      };
    }

    case CONFIRM_METHOD_SUCCESS: {
      return {
        ...state,
        confirming: false,
        confirmed: true,
      };
    }

    case CONFIRM_METHOD_FAILURE: {
      return {
        ...state,
        confirming: false,
        confirmed: false,
      };
    }

    case CONFIRM_MODAL_SHOW: {
      return {
        ...state,
        confirmModal: true,
      };
    }

    case CONFIRM_MODAL_HIDE: {
      return {
        ...state,
        confirmModal: false,
        token: action.payload.data.item.token,
      };
    }

    case CONFIRM_MODAL_CLOSE: {
      return {
        ...state,
        confirmModal: false,
      };
    }

    case REGISTRY_COMPLETED_MESSAGE_SHOW: {
      return {
        ...state,
        registryMessageModal: true,
      };
    }

    case REGISTRY_COMPLETED_MESSAGE_HIDE: {
      return {
        ...state,
        registryMessageModal: false,
      };
    }

    case WRONG_PIN_MESSAGE_SHOW: {
      return {
        ...state,
        wrongConfirmCodeToolTip: true,
      };
    }

    case WRONG_PIN_MESSAGE_HIDE: {
      return {
        ...state,
        wrongConfirmCodeToolTip: false,
      };
    }

    case RESET_PASSWORD_CONFIRM_MODAL_SHOW: {
      return {
        ...state,
        resetPassConfirmModal: true,
      };
    }

    case RESET_PASSWORD_CONFIRM_MODAL_HIDE: {
      return {
        ...state,
        resetPassConfirmModal: false,
      };
    }

    case RESET_PASSWORD_CONFIRM_MODAL_CLOSE: {
      return {
        ...state,
        resetPassConfirmModal: false,
      };
    }

    case PASSWORD_SUCCESS_MODAL_SHOW: {
      return {
        ...state,
        passSuccessMessageModal: true,
      };
    }

    case PASSWORD_SUCCESS_MODAL_HIDE: {
      return {
        ...state,
        passSuccessMessageModal: false,
      };
    }

    case FETCH_USER_ONCOMING_APPS_REQUEST: {
      return {
        ...state,
        fetchingUpcomingApps: true,
      };
    }

    case FETCH_USER_ONCOMING_APPS_FULFILLED: {
      return {
        ...state,
        fetchingUpcomingApps: false,
        upcomingApps: action.payload,
      };
    }

    case FETCH_USER_ONCOMING_APPS_REJECTED: {
      return {
        ...state,
        fetchingUpcomingApps: false,
      };
    }

    case USER_REGISTRY_VALIDATE_PROCESS_START: {
      return {
        ...state,
        verifying: false,
      };
    }

    case EXISTING_ACCOUNT_MESSAGE_HIDE: {
      return {
        ...state,
        existingAccountMsgModal: false,
      };
    }

    case SET_PATIENT_REQUEST: {
      return {
        ...state,
        patientId: action.payload,
      };
    }

    case UTM_PARAMETERS: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    default:
      return state;
  }
};
