/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Buttons = styled.button`
  background-color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  font-family: Roboto;
  border: 0;
  border-radius: 44px;
  color: rgb(255, 255, 255);
  outline: none;
  font-size: 1.3em;
  padding: 18px 30px;
  min-width: 140px;
  margin: ${(props) => props.margin || 'auto'};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  ${(props) => props.styles} &:hover {
    opacity: 0.4;
  }
  @media (max-width: 991px) {
    font-size: 1em;
  }
`;

function ButtonRegistry({
  type,
  onClick,
  disabled,
  children,
  themeColor,
  styles,
}) {
  return (
    <Buttons
      color={themeColor}
      disabled={disabled}
      onClick={onClick}
      type={type}
      styles={styles}
    >
      {children}
    </Buttons>
  );
}

ButtonRegistry.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func, //eslint-disable-line
  type: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
  styles: PropTypes.string,
};

ButtonRegistry.defaultProps = {
  disabled: false,
  type: 'button',
  styles: '',
};

export default ButtonRegistry;
