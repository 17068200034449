import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import {
  avatarIcon,
} from '../icons/userIcons';
import { ProfilePhoto } from '../../elements/profile/ProfileUser';
import { setPatientRequest } from '../../actions/profileActions';
import { getMainAccountPatientId } from '../forms/utils';

const ScheduleButton = styled(Button)`
  padding: 8px 16px;
  margin-bottom: 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  background-color: ${(props) => props.themeColor || '#258ADA'};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;

  /* Center the button on smaller screens */
  @media (max-width: 991px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

export function UserProfile(props) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      ...state,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: props.themeColor,
    }),
  };

  const dispatch = useDispatch();
  const [state, setState] = useState({
    patientId: props.patientId,
    photo: props.accountInfo.photo,
    birthday: props.accountInfo.birthdate,
    phone: props.accountInfo.phone,
    email: props.accountInfo.email,
    familyMembers: props.familyMembers,
    selectedLocation: '',
    mainOfficeInfo: props.mainOfficeInfo,
  });
  const history = useHistory();
  const selectedPatient = props.patientId;

  useEffect((nextProps) => {
    if (!props.familyMembers) {
      props.dispatch(
        setPatientRequest(
          nextProps.familyMembers.length > 0
            ? getMainAccountPatientId(nextProps.familyMembers)
            : 0,
        ),
      );
    }
    if (props.familyMembers) {
      setState((prevState) => ({
        ...prevState,
        familyMembers: props.familyMembers,
      }));
    }
    if (props.patientId !== selectedPatient) {
      setState((prevState) => ({
        ...prevState,
        selectedPatient: props.patientId,
      }));
    }
    if (sessionStorage.getItem('sessionIdLogin')) {
      history.push({
        pathname: '/services',
        search: sessionStorage.getItem('_ga'),
      });
    }
  }, [props, selectedPatient, history]);

  const renderAvatar = (photo, themeColor) => {
    if (photo) {
      return <ProfilePhoto src={photo} />;
    }
    return avatarIcon(themeColor);
  };

  const handlePatientChange = (patientSelect) => {
    setState((prevState) => ({
      ...prevState,
      selectedPatient: patientSelect.value,
    }));

    if (patientSelect) {
      const patient = props.familyMembers.find((x) => x.patient.id === patientSelect.value);

      setState((prevState) => ({
        ...prevState,
        birthday: patient.patient.birthdate,
        phone: patient.patient.phone,
        email: patient.patient.email,
        patientId: patient.patient.id,
      }));
    }

    dispatch(setPatientRequest(patientSelect.value));
  };

  const masterUser = (selectedpatient, familyMembersOptions) => {
    const data = familyMembersOptions.find((x) => x.value === selectedpatient);
    return data;
  };

  const createAppointment = () => {
    history.push({
      pathname: '/services',
      search: sessionStorage.getItem('_ga'),
    });
  };

  return (
    <div className="user-profile">
      <div className="user-profile-image mb-5">
        <div className="user-image">{renderAvatar(state.photo, props.themeColor)}</div>
      </div>
      <div className="user-info">
        <span style={{ color: props.fontcolor }}>Pick a patient to schedule</span>
        <Select
          styles={customStyles}
          className="mt-2 mb-4"
          options={props.familyMembersOptions}
          defaultValue={masterUser(selectedPatient, props.familyMembersOptions)}
          onChange={handlePatientChange}
        />
        <div>
          <ScheduleButton type="button" style={{ backgroundColor: props.themeColor }} onClick={createAppointment}>
            Schedule an appointment
          </ScheduleButton>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontcolor: PropTypes.string.isRequired,
  familyMembers: PropTypes.array.isRequired,
  accountInfo: PropTypes.object.isRequired,
  familyMembersOptions: PropTypes.array.isRequired,
  patientId: PropTypes.number.isRequired,
  mainOfficeInfo: PropTypes.object.isRequired,
};

UserProfile.defaultProps = {
  familyMembers: [],
};

export default connect()(UserProfile);
