import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalHeader, Row, Col,
} from 'react-bootstrap';
import styled from 'styled-components';

const ModalS = styled(Modal)`
  text-align: center;
`;

const Title = styled.span`
  font-size: 1.5em;
  line-height: 1.2;
  color: ${(props) => props.fontcolor || 'rgb(0, 0, 0)'};
  opacity: 0.8;
`;

const DescriptionProvider = styled.div`
  padding: 0px 10px 0;
  position: relative;
  top: 10px;
  text-align: justify;
  > div {
    display: block;
    padding: 20px;
    background: -moz-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontcolor} */
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000', endColorstr='#1e5799',GradientType=1
    ); /* IE6-9 fallback on horizontal gradient */
  }
  hr {
    margin: 50px auto;
  }

  @media (max-width: 991px) {
    top: 0;
    margin: 0 -15px;

    hr {
      margin: 10px auto 30px;
    }
  }
`;

const Avatar = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const AvatarUser = styled(Avatar)`
  width: 120px;
  height: 120px;
  > img {
    border: 1px solid ${(props) => props.color || 'rgb(220, 220, 220)'};
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    margin: 20px auto;
  }
`;

const AvatarTitle = styled.p`
  font-size: 1.4em;
  color: rgb(255, 255, 255);
  margin: 0;
  text-transform: uppercase;
`;

const NameReviews = styled.h4`
  color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  margin: 0;
  text-align: left;

  @media (max-width: 991px) {
    text-align: center;
  }
`;

const SpecialityReviews = styled.h5`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  margin: 0;
  opacity: 0.4;
  text-align: left;

  @media (max-width: 991px) {
    text-align: center;
    margin: 5px 0 15px;
  }
`;

const FlexRowCenter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
  }
`;

class BioModal extends Component {
  constructor() {
    super();
    this.state = {
      /*eslint-disable*/
      children: <div>default</div>,
      /* eslint-enable */
    };
  }

  renderProviderAvatar = (provider, fontcolor) => {
    if (provider.photo) {
      return <img color={fontcolor} alt="" src={provider.photo} />;
    }
    if (Object.keys(provider).length > 0) {
      const intialFirstname = provider.firstName.charAt(0);
      const intialLastname = provider.lastName.charAt(0);
      const initials = `${intialFirstname} ${intialLastname}`;
      return <AvatarTitle>{initials}</AvatarTitle>;
    }
    return false;
  };

  renderProviderInfo = (provider, themeColor, fontcolor) => (
    <Row>
      <Col xs={12} md={12} lg={12}>
        <Col xs={12}>
          <FlexRowCenter>
            <Col xsHidden sm={4}>
              <AvatarUser color={themeColor}>
                {this.renderProviderAvatar(provider, fontcolor)}
              </AvatarUser>
            </Col>
            <Col xsHidden sm={8}>
              <NameReviews color={themeColor}>
                {`${provider.firstName} ${provider.lastName}`}
              </NameReviews>
              <br />
              <SpecialityReviews color={themeColor}>
                {`${provider.specialty}`}
              </SpecialityReviews>
            </Col>
          </FlexRowCenter>
        </Col>
      </Col>
    </Row>
  );

  renderProviderDescription = (biography, fontcolor) => (
    <Row>
      <Col>
        <DescriptionProvider fontcolor={fontcolor}>
          <div>{biography !== '' ? biography : 'N / A'}</div>
          {biography !== '' ? <hr /> : null}
        </DescriptionProvider>
      </Col>
    </Row>
  );

  render() {
    const {
      title, providerInfo, fontcolor, themeColor,
    } = this.props;
    return (
      <ModalS
        aria-labelledby="contained-modal-title-sm"
        backdrop="static"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <ModalHeader closeButton>
          <Title>
            <h4>{title}</h4>
          </Title>
        </ModalHeader>
        <ModalBody>
          {this.renderProviderInfo(providerInfo, themeColor, fontcolor)}
          {this.renderProviderDescription(providerInfo.biography, fontcolor)}
        </ModalBody>
      </ModalS>
    );
  }
}
BioModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  providerInfo: PropTypes.object.isRequired,
  fontcolor: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
};

export default BioModal;
